import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { useAnimation, motion, useInView } from "framer-motion";
import Link from "next/link";

export default function Services({ services }: any) {
  const ref: any = useRef();
  const inView = useInView(ref, { once: true, amount: 0.49999 });
  const controls = useAnimation();
  useEffect(() => {
    controls.start("visible");
  }, [controls, inView]);
  return (
    <div className="mt-[80px] pb-[90px]">
      <h2 className="text-black text-[30px] font-extrabold text-center">
        Servicios
      </h2>
      <div className="mx-auto w-[50px] h-[2px] bg-primary mt-3"></div>
      <div className="grid grid-cols-1 md:grid-cols-2 w-[90%] mx-auto mt-8 gap-6">
        <div className="grid grid-cols-2 gap-5 mt-12 md:mt-0 col-span-1">
          <div className="w-full flex justify-end flex-wrap">
            {[
              { i: 5, width: 214, height: 156 },
              { i: 6, width: 300, height: 232 },
            ].map((img: any, index: number) => (
              <motion.div
                key={index}
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={{
                  visible: {
                    opacity: 1,
                    scale: 1,
                    transition: { duration: 1 + index },
                    left: "0px",
                  },
                  hidden: {
                    opacity: 0,
                    scale: 1,
                    left: "81px",
                  },
                }}
              >
                <div className="mb-3">
                  <img
                    src={`/images/png/banner/banner-${img.i}.png`}
                    width={img.width}
                    height={img.height}
                    alt={`banner-${img.i}`}
                  />
                </div>
              </motion.div>
            ))}
          </div>
          <div className="mt-12">
            {[{ i: 7, width: 236, height: 286 }].map(
              (img: any, index: number) => (
                <motion.div
                  key={index}
                  ref={ref}
                  animate={controls}
                  initial="hidden"
                  variants={{
                    visible: {
                      opacity: 1,
                      scale: 1,
                      transition: { duration: 1 + index },
                      left: "0px",
                    },
                    hidden: {
                      opacity: 0,
                      scale: 1,
                      left: "81px",
                    },
                  }}
                >
                  <div className="mb-3">
                    <img
                      src={`/images/png/banner/banner-${img.i}.png`}
                      width={img.width}
                      height={img.height}
                      alt={`banner-${img.i}`}
                    />
                  </div>
                </motion.div>
              )
            )}
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 mt-12 col-span-1">
          {services.map(({ name, description }: any, index: number) => (
            <motion.div
              key={index}
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={{
                visible: {
                  opacity: 1,
                  scale: 1,
                  transition: { duration: 1 + index },
                  left: `0px`,
                },
                hidden: {
                  opacity: 0,
                  scale: 1,
                  left: `81px`,
                },
              }}
            >
              <div className="mb-8 md:mb-0">
                <h4 className="text-black font-bold text-[18px]">{name}</h4>
                <p className="font-medium text-[16px] text-black">
                  {description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
        <div className="col-span-1"></div>
        <div className="col-span-1">
          <p className="font-bold mb-[15px] text-[18px]">Bounce Partners</p>
          <Link
            href="https://bounce.com/es/city/panama-city"
            target="_blank"
            className="inline-block"
          >
            <img
              className="min-w-[140px] h-auto"
              width="140px"
              height="39px"
              src="/images/webp/bounce-logo.webp"
              alt="bounce logo"
            />
          </Link>
          <p className="mt-[19px] font-medium">
            En nuestro Hub de Innovación, te <br /> ofrecemos la posibilidad de
            dejar tu <br /> equipaje con total seguridad y <br /> confianza
            mediante Bounce.
          </p>
        </div>
      </div>
    </div>
  );
}
